export enum ErrorCodes {
  UserDisabled = 'userDisabled',
  UnverifiedEmail = 'unverifiedEmail',
  EmailPasswordAccountExists = 'emailExists',
  AccountExists = 'accountExists',
  InvalidEmailOrPassword = 'invalidEmailOrPassword',
  NotAllowed = 'notAllowed',
  WeakPassword = 'weakPassword',
  GenericAuthFailure = 'genericAuthFailure',
  InviteExpired = 'inviteExpired',
  InvalidInvite = 'invalidInvite',
  LinkExpired = 'linkExpired',
  InvalidLink = 'invalidLink',
  NetworkFailure = 'networkFailure',
  IntermittentError = 'tryAgain',

  EmailBelongsToExistingOrg = 'pmitc.signup.emailBelongsToExistingOrg',
  FailedCreateOrg = 'pmitc.signup.failedCreateOrg',
  EmailAndUserIDMismatch = 'pmitc.login.emailAndUserIDMismatch',
}

export class ErrorMessage {
  title?: string
  content?: string
}

export const toMessage = (errCode: string): ErrorMessage => {
  const errorMessage = new ErrorMessage()
  switch (errCode) {
    case ErrorCodes.UserDisabled:
      errorMessage.content = `User was disabled.`
      break
    case ErrorCodes.NotAllowed:
      errorMessage.content = `Your account does not have access to this organization.`
      break
    case ErrorCodes.InvalidEmailOrPassword:
      errorMessage.content = `Invalid email or password.`
      break
    case ErrorCodes.EmailPasswordAccountExists:
      errorMessage.content = `You already have an account, enter your existing credentials and log in instead.`
      break
    case ErrorCodes.AccountExists:
      errorMessage.content = `You already have an account, log in to your account.`
      break
    case ErrorCodes.WeakPassword:
      errorMessage.content = `Password is too weak.`
      break
    case ErrorCodes.GenericAuthFailure:
      errorMessage.content = `Login failed. Please try again.`
      break
    case ErrorCodes.FailedCreateOrg:
      errorMessage.content = `Oops! This is embarrassing and should not happen. Please try to log in again. If the error persists, please contact us at support@papercut.com. (Error code: OxDECAFE).`
      break
    case ErrorCodes.EmailBelongsToExistingOrg:
      errorMessage.content = `You have already set up your account, please try to log in again.`
      break
    case ErrorCodes.EmailAndUserIDMismatch:
      errorMessage.content = `You have signed up with a different login method, please try to log in with that method again.`
      break
    case ErrorCodes.InviteExpired:
      errorMessage.content = `Invitation has expired. To request a new invitation, contact your admin.`
      break
    case ErrorCodes.InvalidInvite:
      errorMessage.content = `Something went wrong. To request a new invitation, contact your admin.`
      break
    case ErrorCodes.LinkExpired:
      errorMessage.title = `Link expired or already used`
      errorMessage.content = `Log in to continue`
      break
    case ErrorCodes.InvalidLink:
      errorMessage.title = `Something went wrong`
      errorMessage.content = `Are you sure this is the right link? Contact your admin.`
      break
    case ErrorCodes.NetworkFailure:
      errorMessage.content = `Unable to log in. Check firewall and network allowed URL settings, then try again. Learn more: https://www.papercut.com/help/manuals/pocket-hive/plan-and-get-started/system-requirements/`
      break
    case ErrorCodes.IntermittentError:
      errorMessage.content = 'Something went wrong, please try again'
      break
    default:
      errorMessage.content = `Unknown error`
  }

  return errorMessage
}

export const errorIs = (error: unknown, target: ErrorCodes): boolean => {
  if (typeof error === 'string') {
    return error === target
  } else if (error instanceof Error) {
    return error.message === target
  }

  return false
}
