import axiosInstance from '.'
import { Product } from '@/context/product'

export async function sendResetPasswordEmail(
  email: string,
  tenantID: string,
  continueURL: string,
  productName?: string,
) {
  const body = {
    email: email,
    tenant_id: tenantID,
    product_name: productName,
    continue_url: continueURL,
  }

  await axiosInstance.post('/email/send-reset-password-email', body)
}

export async function sendVerificationEmail(email: string, tenantID: string, product: Product) {
  const body = {
    email: email,
    tenant_id: tenantID,
    product_name: product.getName(),
    product_id: product.getId(),
    continue_url: product.getRedirectUrl(),
  }

  await axiosInstance.post('/email/send-verification-email', body)
}

export async function resendVerificationEmail(email: string, tenantID: string, product: Product) {
  const body = {
    email: email,
    tenant_id: tenantID,
    product_name: product.getName(),
    product_id: product.getId(),
  }
  // we don't pass in the current product URL instead we use the previous product URL that the server has persisted for this user

  await axiosInstance.post('/email/send-verification-email', body)
}
