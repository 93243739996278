import { FirebaseApp, FirebaseOptions, initializeApp } from 'firebase/app'
import { Auth, AuthErrorCodes as FirebaseErrorCodes, getAuth, updateProfile } from 'firebase/auth'
import { signInWithEmailAndPassword, createUserWithEmailAndPassword } from '@firebase/auth'
import { config } from '@/utils/config'
import { ErrorCodes } from '@/utils/errorCodes'

interface FirebaseErrorWithCode extends Error {
  code?: string
}

let firebaseApp: FirebaseApp

export function getFirebaseAuth(tenantId: string | null): Auth {
  if (!firebaseApp) {
    const firebaseOptions: FirebaseOptions = {
      apiKey: config().firebase.apiKey,
      authDomain: config().firebase.authDomain,
    }
    firebaseApp = initializeApp(firebaseOptions)
  }
  const firebaseAuth = getAuth(firebaseApp)
  firebaseAuth.tenantId = tenantId

  return firebaseAuth
}

export async function signInWithEmailPassword(tenantId: string, email: string, password: string): Promise<string> {
  const firebaseAuth = getFirebaseAuth(tenantId)
  try {
    const userCredentials = await signInWithEmailAndPassword(firebaseAuth, email, password)
    if (!userCredentials.user.emailVerified) {
      throw new Error(FirebaseErrorCodes.UNVERIFIED_EMAIL)
    }

    const result = await userCredentials.user.getIdTokenResult(false)

    return result.token
  } catch (err) {
    console.error(`signInWithEmailAndPassword: ${err}`)
    throw translateFirebaseError(err)
  }
}

export async function signUpWithEmailPassword(
  tenantId: string,
  email: string,
  password: string,
  name: string,
): Promise<void> {
  const firebaseAuth = getFirebaseAuth(tenantId)
  try {
    const userCredentials = await createUserWithEmailAndPassword(firebaseAuth, email, password)
    await updateProfile(userCredentials.user, {
      displayName: name,
      photoURL: '',
    })
  } catch (err) {
    console.error(`signUpWithEmailAndPassword: ${err}`)
    throw translateFirebaseError(err)
  }
}

// Translate firebase error to identity error as we don't want to couple with Firebase.
// Firebase error list https://firebase.google.com/docs/auth/admin/errors
export function translateFirebaseError(error: unknown): ErrorCodes {
  const errorCode = extractErrorCode(error)

  switch (errorCode) {
    case 'email-not-allowed':
      return ErrorCodes.NotAllowed
    case FirebaseErrorCodes.UNVERIFIED_EMAIL:
      return ErrorCodes.UnverifiedEmail
    case FirebaseErrorCodes.INVALID_PASSWORD:
      return ErrorCodes.InvalidEmailOrPassword
    case FirebaseErrorCodes.WEAK_PASSWORD:
      return ErrorCodes.InvalidEmailOrPassword
    case FirebaseErrorCodes.USER_DISABLED:
      return ErrorCodes.UserDisabled
    case FirebaseErrorCodes.USER_DELETED:
      return ErrorCodes.InvalidEmailOrPassword
    case FirebaseErrorCodes.EMAIL_EXISTS:
      return ErrorCodes.EmailPasswordAccountExists
    case FirebaseErrorCodes.NETWORK_REQUEST_FAILED:
      return ErrorCodes.NetworkFailure
    default:
      return ErrorCodes.GenericAuthFailure
  }
}

function extractErrorCode(error: unknown): string | null {
  if (error instanceof Error) {
    const customError = error as FirebaseErrorWithCode

    return extractFirebaseAuthErrorCode(customError.message) || customError.code || customError.message
  }

  return null
}

type FirebaseErrorCode = (typeof FirebaseErrorCodes)[keyof typeof FirebaseErrorCodes]

// Extract Firebase AuthErrorCodes.<ERROR_CODE> from error.message
function extractFirebaseAuthErrorCode(errorMessage: string): string | null {
  if (Object.values(FirebaseErrorCodes).includes(errorMessage as FirebaseErrorCode)) {
    return errorMessage
  }

  return extractCustomErrorCode(errorMessage)
}

// Extract a custom error code set by Papercut’s code in the before sign-in / before create firebase hooks
function extractCustomErrorCode(errorMessage: string): string | null {
  const regex = /"message":"(.*?)"/
  const match = regex.exec(errorMessage)

  return match ? match[1] : null
}
