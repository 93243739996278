import { Button } from '@papercutsoftware/pcds-react'
import { useRouter } from 'next/router'
import styled from '@emotion/styled'

interface BackButtonProps {
  onClick?: () => void
}

const StyledBackButtonContainer = styled.div`
  button {
    margin-left: -8px;
  }
`

export const BackButton: React.FC<BackButtonProps> = ({ onClick }) => {
  const router = useRouter()

  return (
    <StyledBackButtonContainer>
      <Button
        iconStyle="bold"
        startIconName="arrowLeft"
        variation="transparent"
        size="small"
        label="Back"
        action={onClick ? onClick : () => router.back()}
      />
    </StyledBackButtonContainer>
  )
}
