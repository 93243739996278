import { PageUrlBuilder } from '@/utils/pageurl/pageurl'
import { Product } from '@/context/product'

export class ForgotPasswordPageUrl extends PageUrlBuilder {
  constructor(product: Product, tenantID: string) {
    const urlPath = 'forgot-password'
    super(urlPath, product)
    this.withTenant(tenantID)
    this.withProductPostLoginUrl()
  }
}
