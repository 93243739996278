'use client'

import { FC, useContext, useState } from 'react'
import { Divider, Link, Typography } from '@papercutsoftware/pcds-react'
import { useRouter } from 'next/router'
import { useSearchParams } from 'next/navigation'
import { IdentityProvider, ProviderType } from '@/api/identityProviders'
import { AuthFlow, AuthFlowContext } from '@/context/authflow'
import EmailPasswordSignup from '@/components/EmailPasswordSignup/EmailPasswordSignup'
import EmailPasswordLogin from '@/components/EmailPasswordLogin/EmailPasswordLogin'
import PublicOAuthLogin from '@/components/PublicOAuthLogin/PublicOAuthLogin'
import { ErrorMessage } from '@/utils/errorCodes'

interface Props {
  tenantId: string
  providers: IdentityProvider[]
  onAuthSuccess: (idToken: string) => void
  onAuthFailure: (err: unknown) => void
  setAuthError: (errMsg: ErrorMessage) => void
  prefilledEmail?: string
  onVisibilityChange: (visible: boolean) => void
}

const OtherLoginOptions: FC<Props> = ({
  tenantId,
  providers,
  onAuthSuccess,
  onAuthFailure,
  setAuthError,
  prefilledEmail,
  onVisibilityChange,
}) => {
  const hasEmailPassword = providers.some((idP) => idP.type === ProviderType.Password)
  const hasPublicOAuth = providers.some((idP) => idP.type === ProviderType.Social)
  const hasSSOLogin = providers.some((idP) => idP.type === ProviderType.SAML)
  const authFlow = useContext(AuthFlowContext)

  const [isVisible, setIsVisible] = useState<boolean>(!hasPublicOAuth)
  if (!hasPublicOAuth) {
    onVisibilityChange(true)
  }

  const { pathname } = useRouter()
  const queryParams = useSearchParams()
  const ssoLoginURL = () => {
    const searchParams = new URLSearchParams()
    queryParams.forEach((value, key) => searchParams.set(key, value))
    searchParams.set('authOption', 'sso')

    return `${pathname}?${searchParams.toString()}`
  }

  return (
    <>
      <PublicOAuthLogin
        tenantId={tenantId!}
        providers={providers}
        onAuthSuccess={onAuthSuccess}
        onAuthFailure={(err) => {
          onAuthFailure(err)
          setIsVisible(true)
        }}
        onVisibilityChange={(isVisible) => {
          setIsVisible(isVisible)
          onVisibilityChange(isVisible)
        }}
        setAuthError={setAuthError}
      />
      {isVisible && (
        <>
          {hasEmailPassword && hasPublicOAuth && <Divider flexItem variant="fullWidth" label="or" />}
          {hasEmailPassword && (
            <>
              {authFlow === AuthFlow.Signup ? (
                <EmailPasswordSignup tenantId={tenantId} prefilledEmail={prefilledEmail!} setAuthError={setAuthError} />
              ) : (
                <EmailPasswordLogin
                  tenantId={tenantId}
                  prefilledEmail={prefilledEmail}
                  onAuthSuccess={onAuthSuccess}
                  setAuthError={setAuthError}
                />
              )}
              {hasSSOLogin && (
                <Typography variant="label" align="left">
                  <Link href={ssoLoginURL()} target="_self">
                    Log in with SSO
                  </Link>
                </Typography>
              )}
            </>
          )}
        </>
      )}
    </>
  )
}

export default OtherLoginOptions
