import { FC, type FormEvent, useContext, useState } from 'react'
import { Alert, Link, PasswordField, Stack, SubmitButton, TextField, Typography } from '@papercutsoftware/pcds-react'
import { useRouter } from 'next/router'
import { useSearchParams } from 'next/navigation'
import { ProductContext } from '@/context/product'
import { signInWithEmailPassword } from '@/utils/firebase/firebaseAuth'
import { isValidEmail } from '@/utils/emailValidation'
import { ErrorCodes, errorIs, ErrorMessage, toMessage } from '@/utils/errorCodes'
import { VerifyEmailPageUrl } from '@/utils/pageurl/verifyemailpageurl'
import { ForgotPasswordPageUrl } from '@/utils/pageurl/forgotpasswordpageurl'
import { LoginPageUrlBuilder } from '@/utils/pageurl/loginpageurl'

interface Props {
  tenantId: string
  prefilledEmail?: string
  onAuthSuccess: (idToken: string) => void
  setAuthError: (errMsg: ErrorMessage) => void
}

export const SuccessQueryParamKey = 'success'
export const RESET_PASSWORD_SUCCESS_KEY = 'RESET_PASSWORD'

const EmailPasswordLogin: FC<Props> = ({ tenantId, prefilledEmail, onAuthSuccess, setAuthError }) => {
  const [email, setEmail] = useState<string | undefined>(prefilledEmail)
  const [emailValidationError, setEmailValidationError] = useState<string | null>(null)
  const [password, setPassword] = useState<string>('')
  const [passwordValidationError, setPasswordValidationError] = useState<string | null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const product = useContext(ProductContext)
  const router = useRouter()
  const searchParams = useSearchParams()
  const hasResetPassword = searchParams.get(SuccessQueryParamKey) === RESET_PASSWORD_SUCCESS_KEY

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault()
    setEmailValidationError('')
    setPasswordValidationError('')
    const emailString = email?.trim()
    if (!emailString) {
      setEmailValidationError('Email address is required')

      return
    }

    if (!isValidEmail(emailString)) {
      setEmailValidationError('Invalid email')

      return
    }

    if (!password?.trim()) {
      setPasswordValidationError('Password is required')

      return
    }

    try {
      setIsLoading(true)
      const idToken = await signInWithEmailPassword(tenantId, emailString, password)
      onAuthSuccess(idToken)
    } catch (e: unknown) {
      console.error(`signInWithEmailPassword error: ${JSON.stringify(e)}`)
      if (errorIs(e, ErrorCodes.UnverifiedEmail)) {
        const verifyEmailPageUrl = new VerifyEmailPageUrl(product, tenantId, emailString).build()
        await router.push(verifyEmailPageUrl)
      } else {
        setAuthError(toMessage(e as string))
      }
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      {hasResetPassword ? (
        <Alert severity="success" title="Password updated" content="To log in, use your password." />
      ) : null}
      <form onSubmit={handleSubmit} data-testid="email-password-login-form">
        <Stack direction="column" spacing={3}>
          <Stack direction="column" spacing={2}>
            <TextField
              id="login-email"
              data-testid="login-email"
              label="Email address"
              placeholder="Enter your email address"
              onChange={(e) => {
                const newValue = e.target.value
                if (newValue !== null && newValue !== undefined) {
                  setEmail(newValue.trim())
                }
              }}
              defaultValue={email}
              error={Boolean(emailValidationError)}
              errorMessage={emailValidationError || undefined}
              autoComplete="on"
            />
            <PasswordField
              placeholder="Enter your password"
              label="Password"
              id="password"
              data-testid="password"
              error={Boolean(passwordValidationError)}
              errorMessage={passwordValidationError || undefined}
              onChange={(e) => {
                const newValue = e.target.value
                if (newValue !== null && newValue !== undefined) {
                  setPassword(newValue)
                }
              }}
            />
            <Typography variant="body2" align="left">
              <Link
                href={new ForgotPasswordPageUrl(product, tenantId).withPrefilledEmailAddress(email ?? '').build()}
                target="_self"
              >
                Forgot your password?
              </Link>
            </Typography>
            <Typography variant="body2" align="left">
              {"Don't have an email account? "}
              <Link
                href={new LoginPageUrlBuilder(product)
                  .withSignUp()
                  .withOtherAuthOption()
                  .withTenant(tenantId)
                  .withPrefilledEmailAddress(email ?? '')
                  .build()}
                target="_self"
              >
                {'Sign Up'}
              </Link>
            </Typography>
          </Stack>
          <Stack direction="column" spacing={3}>
            <SubmitButton label="Log in with email" loading={isLoading} data-testid="login-with-email-button" />
          </Stack>
        </Stack>
      </form>
    </>
  )
}
export default EmailPasswordLogin
