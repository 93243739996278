import { FC, useContext } from 'react'
import { Typography } from '@papercutsoftware/pcds-react'
import { ProductContext } from '@/context/product'
import { AuthFlowContext, AuthFlow } from '@/context/authflow'

const LoginSignUpPageHeading: FC = () => {
  const authFlow = useContext(AuthFlowContext)
  const product = useContext(ProductContext)
  let titlePrefix = 'Log in'
  if (authFlow === AuthFlow.Signup) {
    titlePrefix = 'Sign up'
  }

  return (
    <Typography color="heading" variant="h2" component="h2" data-testid="page-heading">
      {titlePrefix} to {product.getName()}
    </Typography>
  )
}

export default LoginSignUpPageHeading
