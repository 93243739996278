import { UserCredential } from 'firebase/auth'
import firebaseui from 'firebaseui'
import { CustomParameters } from '@firebase/auth'
import { IdentityProvider } from '@/api/identityProviders'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type FirebaseProvider = any // setting to any as firebase ui does not export the types however the available types can be seen in firebaseui.auth.Config.signInOptions or by looking at signInOptions

export const getFirebaseUiConfig = (
  providers: IdentityProvider[],
  onAuthSuccess: (authResult: UserCredential) => void,
): firebaseui.auth.Config => {
  const firebaseProviders: FirebaseProvider[] = providers.map((provider): FirebaseProvider => {
    return {
      provider: provider.id,
      providerName: provider.name,
      fullLabel: getButtonLabel(provider),
      iconUrl: provider.signInButton?.logoUrl,
      customParameters: getProviderCustomParameters(),
    }
  })

  return {
    // https://github.com/firebase/firebaseui-web
    signInFlow: 'redirect',
    signInOptions: firebaseProviders,
    callbacks: {
      signInSuccessWithAuthResult: (authResult: UserCredential) => {
        onAuthSuccess(authResult)

        return false
      },
    },
  }
}

const getButtonLabel = (provider: IdentityProvider): string => {
  if (provider.signInButton?.label) {
    return provider.signInButton.label
  }

  return `Continue with ${provider.name}`
}

export const getProviderCustomParameters = (): CustomParameters => {
  return {
    prompt: 'select_account', // TODO check provider vendor and add the `prompt` param for Google and Microsoft or any other vendors that support it just in case other vendors use `prompt` to do something completely different
  }
}
