import { PageUrlBuilder } from '@/utils/pageurl/pageurl'
import { Product } from '@/context/product'

export const authOptionQueryParam = 'authOption'
export const signUpQueryParam = 'signup'

export class LoginPageUrlBuilder extends PageUrlBuilder {
  constructor(product: Product) {
    super('', product)
    this.withProductPostLoginUrl()
  }

  withSignUp(): LoginPageUrlBuilder {
    this.queryParams.set(signUpQueryParam, '')

    return this
  }

  withOtherAuthOption(): LoginPageUrlBuilder {
    this.queryParams.set(authOptionQueryParam, 'other')

    return this
  }
}
