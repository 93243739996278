import axiosInstance from '@/api/index'

export interface Response {
  identityProviders: IdentityProvider[]
}

export enum ProviderType {
  SAML = 'SAML',
  Social = 'SOCIAL',
  Password = 'PASSWORD',
}

export enum ProviderVendor {
  Google = 'GOOGLE',
  Microsoft = 'MICROSOFT',
  Undefined = 'UNDEFINED_TYPE',
}

export interface IdentityProvider {
  id: string
  name: string
  type: ProviderType
  vendor: ProviderVendor
  signInButton: SignInButton | null
}

export interface SignInButton {
  label: string
  logoUrl: string
}

export async function getIdentityProviders(tenantId: string): Promise<Response | undefined> {
  const { data } = await axiosInstance.get(`/tenant/${tenantId}/identity-providers`)

  return data as Response
}
