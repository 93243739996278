import { PageUrlBuilder } from '@/utils/pageurl/pageurl'
import { Product } from '@/context/product'

export class VerifyEmailPageUrl extends PageUrlBuilder {
  constructor(product: Product, tenantID: string, emailAddress: string) {
    const urlPath = 'verify-email'
    super(urlPath, product)
    this.withTenant(tenantID)
    this.withPrefilledEmailAddress(emailAddress)
  }
}
