import type { FC } from 'react'
import { IdentityProvider, ProviderType } from '@/api/identityProviders'
import FirebaseUiAuth from '@/components/firebase/ui/firebaseUiAuth'
import { ErrorMessage } from '@/utils/errorCodes'

interface Props {
  tenantId: string
  providers: IdentityProvider[]
  onAuthSuccess: (idToken: string) => void
  onAuthFailure: (err: unknown) => void
  onVisibilityChange: (visible: boolean) => void
  setAuthError: (errMsg: ErrorMessage) => void
}

const PublicOAuthLogin: FC<Props> = ({
  tenantId,
  providers,
  onAuthSuccess,
  onAuthFailure,
  onVisibilityChange,
  setAuthError,
}) => {
  const publicOAuthProviders = providers
    .filter((value) => value.type === ProviderType.Social)
    .sort((a, b) => {
      if (a.name === b.name) return 0
      if (a.name == null) return 1
      if (b.name == null) return -1

      return a.name.localeCompare(b.name)
    })

  return (
    publicOAuthProviders.length > 0 && (
      <FirebaseUiAuth
        containerId="firebaseui-auth-container-public-oauth"
        tenantId={tenantId!}
        providers={publicOAuthProviders}
        onAuthSuccess={onAuthSuccess}
        onAuthFailure={onAuthFailure}
        onRedirectStatusChange={(redirecting) => {
          onVisibilityChange(!redirecting)
        }}
        setAuthError={setAuthError}
      />
    )
  )
}

export default PublicOAuthLogin
