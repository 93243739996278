import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { Loader, Stack, Typography } from '@papercutsoftware/pcds-react'
import { IdentityProvider } from '@/api/identityProviders'
import { getFirebaseUiConfig } from '@/utils/firebase/firebaseUiConfig'
import { getFirebaseAuth, translateFirebaseError } from '@/utils/firebase/firebaseAuth'
import { StyledFirebaseUi } from '@/styles/firebaseUi.styles'
import { ErrorCodes, errorIs, ErrorMessage, toMessage } from '@/utils/errorCodes'
import { renderError } from '@/components/RenderAlert/RenderErrorAlert'

type FirebaseUiAuthProps = {
  tenantId: string
  providers: IdentityProvider[]
  onAuthSuccess: (idToken: string) => void
  onAuthFailure: (err: unknown) => void
  onRedirectStatusChange?: (redirecting: boolean) => void
  setAuthError: (errMsg: ErrorMessage) => void
  containerId: string
}

// A re-implementation of `react-firebaseui` which we cannot use as it only supports up to react 17 and the repository doesn't appear to be maintained for quite some time.
const FirebaseUiAuth: FC<FirebaseUiAuthProps> = ({
  tenantId,
  providers,
  onAuthSuccess,
  onAuthFailure,
  onRedirectStatusChange,
  setAuthError,
  containerId,
}) => {
  const [pendingRedirect, setPendingRedirect] = useState<boolean>(false)
  const [initError, setInitError] = useState<ErrorMessage | null>(null)

  const ui = useMemo(() => {
    // Import firebaseui.auth dynamically to avoid issues with SSR or window not being defined
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const firebaseui = require('firebaseui').auth

    return firebaseui.AuthUI.getInstance() || new firebaseui.AuthUI(getFirebaseAuth(tenantId))
  }, [tenantId])

  const changeRedirectStatus = useCallback(
    (redirecting: boolean) => {
      setPendingRedirect(redirecting)
      if (onRedirectStatusChange) {
        onRedirectStatusChange(redirecting)
      }
    },
    [onRedirectStatusChange],
  )

  useEffect(() => {
    const intervalId = setInterval(() => {
      const firebaseErrorString = document
        .querySelector('.firebaseui-info-bar-message')
        ?.childNodes[0]?.textContent?.trim()
      if (firebaseErrorString) {
        console.error(`failed to authenticate: ${firebaseErrorString}`)
        document.querySelector('.firebaseui-info-bar')?.remove()
        const rawError = new Error(firebaseErrorString)
        const errorCode = translateFirebaseError(rawError)
        if (errorIs(errorCode, ErrorCodes.GenericAuthFailure)) {
          const errorMessage = new ErrorMessage()
          errorMessage.title = 'Something went wrong'
          errorMessage.content = firebaseErrorString
          setAuthError(errorMessage)
        } else {
          setAuthError(toMessage(errorCode))
        }
        onAuthFailure(rawError)
        changeRedirectStatus(false)
      }
    }, 1000)

    return () => clearInterval(intervalId)
  })

  useEffect(() => {
    const startUi = async () => {
      if (!ui) {
        const errorMessage = new ErrorMessage()
        errorMessage.content = 'Firebase UI is not initialized.'
        setInitError(errorMessage)

        return
      }

      try {
        const uiConfig = getFirebaseUiConfig(providers, async (authResult) => {
          try {
            const idTokenResult = await authResult.user.getIdTokenResult(false)
            onAuthSuccess(idTokenResult.token)
          } catch (err) {
            onAuthFailure(err)
            changeRedirectStatus(false)
          }
        })
        changeRedirectStatus(ui.isPendingRedirect())
        await ui.start(`#${containerId}`, uiConfig)
      } catch (error) {
        const errorMessage = new ErrorMessage()
        errorMessage.content = 'Failed to initialize Firebase UI'
        setInitError(errorMessage)
      }
    }
    startUi()
  }, [tenantId, providers, ui, onAuthSuccess, onAuthFailure, containerId, changeRedirectStatus])

  return (
    <>
      {initError ? renderError(initError) : <></>}
      <StyledFirebaseUi>
        <div
          data-testid="firebase-ui-container"
          id={containerId}
          style={{
            display: pendingRedirect ? 'none' : 'block',
          }}
        />
      </StyledFirebaseUi>
      {pendingRedirect && (
        <>
          <Stack alignItems="center" direction="column">
            <Loader />
            <Typography>Authenticating</Typography>
          </Stack>
        </>
      )}
    </>
  )
}

export default FirebaseUiAuth
