import { FC, type FormEvent, useContext, useState } from 'react'
import { Link, PasswordField, Stack, SubmitButton, TextField, Typography } from '@papercutsoftware/pcds-react'
import { useRouter } from 'next/router'
import { ProductContext } from '@/context/product'
import { signUpWithEmailPassword } from '@/utils/firebase/firebaseAuth'
import { sendVerificationEmail } from '@/api/email'
import { isValidEmail } from '@/utils/emailValidation'
import { ErrorCodes, errorIs, ErrorMessage, toMessage } from '@/utils/errorCodes'
import { validatePasswordStrength, PasswordCriteriaLabelDescription } from '@/utils/passwordValidation'
import { VerifyEmailPageUrl } from '@/utils/pageurl/verifyemailpageurl'
import { LoginPageUrlBuilder } from '@/utils/pageurl/loginpageurl'

interface Props {
  tenantId: string
  prefilledEmail?: string
  setAuthError: (errMsg: ErrorMessage) => void
}

const EmailPasswordSignup: FC<Props> = ({ tenantId, prefilledEmail, setAuthError }) => {
  const [email, setEmail] = useState<string | undefined>(prefilledEmail)
  const [emailValidationError, setEmailValidationError] = useState<string | null>(null)
  const [password, setPassword] = useState<string>('')
  const [passwordValidationError, setPasswordValidationError] = useState<string | null>(null)
  const [name, setName] = useState<string>('')
  const [nameValidationError, setNameValidationError] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const product = useContext(ProductContext)
  const router = useRouter()

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault()
    setEmailValidationError('')
    setPasswordValidationError('')
    const emailString = email?.trim()
    if (!emailString) {
      setEmailValidationError('Email address is required')

      return
    }

    if (!isValidEmail(emailString)) {
      setEmailValidationError('Invalid email')

      return
    }

    if (!password?.trim()) {
      setPasswordValidationError('Password is required')

      return
    }

    if (!name?.trim()) {
      setNameValidationError('Required')

      return
    }

    try {
      validatePasswordStrength(password)
    } catch (err) {
      setPasswordValidationError((err as Error).message)

      return
    }

    try {
      setIsLoading(true)
      await signUpWithEmailPassword(tenantId, emailString, password, name)
      await sendVerificationEmail(emailString, tenantId, product)
      const verifyEmailPageUrl = new VerifyEmailPageUrl(product, tenantId, emailString).build()
      await router.push(verifyEmailPageUrl)
    } catch (e: unknown) {
      if (typeof e === 'string') {
        if (errorIs(e, ErrorCodes.EmailPasswordAccountExists)) {
          const { pathname, query } = router
          delete router.query.signup
          await router.push({ pathname, query }, undefined, { shallow: true })
        }
        setAuthError(toMessage(e))
      } else if (e instanceof Error) {
        console.error(`signUpWithEmailPassword error: ${JSON.stringify(e)}`)
        setAuthError(toMessage(e.message))
      } else {
        console.error('An unexpected error occurred:', e)
        setAuthError(toMessage('An unexpected error occurred.'))
      }
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <form onSubmit={handleSubmit} data-testid="email-password-signup-form">
      <Stack direction="column" spacing={3}>
        <Stack direction="column" spacing={2}>
          <TextField
            id="signup-name"
            data-testid="signup-name"
            label="Name"
            placeholder="Enter your name"
            onChange={(e) => {
              const newValue = e.target.value
              if (newValue !== null && newValue !== undefined) {
                setName(newValue.trim())
              }
            }}
            error={Boolean(nameValidationError)}
            errorMessage={nameValidationError || undefined}
          />
          <TextField
            id="signup-email"
            data-testid="signup-email"
            label="Email address"
            placeholder="Enter your email address"
            onChange={(e) => {
              const newValue = e.target.value
              if (newValue !== null && newValue !== undefined) {
                setEmail(newValue.trim())
              }
            }}
            defaultValue={email}
            error={Boolean(emailValidationError)}
            errorMessage={emailValidationError || undefined}
            autoComplete="on"
          />
          <PasswordField
            placeholder="Enter your password"
            label="Password"
            id="password"
            data-testid="password"
            labelDescription={PasswordCriteriaLabelDescription}
            error={Boolean(passwordValidationError)}
            errorMessage={passwordValidationError || undefined}
            onChange={(e) => {
              const newValue = e.target.value
              if (newValue !== null && newValue !== undefined) {
                setPassword(newValue)
              }
            }}
          />
          <Typography variant="body2" align="left">
            {'Already have an email account? '}
            <Link
              href={new LoginPageUrlBuilder(product)
                .withOtherAuthOption()
                .withTenant(tenantId)
                .withPrefilledEmailAddress(email ?? '')
                .build()}
              target="_self"
            >
              {'Log In'}
            </Link>
          </Typography>
        </Stack>
        <Stack direction="column" spacing={3}>
          <SubmitButton label="Sign up with email" loading={isLoading} data-testid="login-with-email-button" />
        </Stack>
      </Stack>
    </form>
  )
}
export default EmailPasswordSignup
