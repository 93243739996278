import { FC, useState } from 'react'
import { Link, Stack, Typography } from '@papercutsoftware/pcds-react'
import { useRouter } from 'next/router'
import { useSearchParams } from 'next/navigation'
import FirebaseUiAuth from '@/components/firebase/ui/firebaseUiAuth'
import { IdentityProvider, ProviderType } from '@/api/identityProviders'
import { ErrorMessage } from '@/utils/errorCodes'

interface Props {
  tenantId: string
  providers: IdentityProvider[]
  onAuthSuccess: (idToken: string) => void
  onAuthFailure: (err: unknown) => void
  onVisibilityChange: (visible: boolean) => void
  setAuthError: (errMsg: ErrorMessage) => void
}

const SSOLogin: FC<Props> = ({
  tenantId,
  providers,
  onAuthSuccess,
  onAuthFailure,
  onVisibilityChange,
  setAuthError,
}) => {
  const [isVisible, setIsVisible] = useState<boolean>(false)

  const ssoProviders = providers
    .filter((value) => value.type === ProviderType.SAML)
    .sort((a, b) => a.name.localeCompare(b.name))
  const hasOtherLoginOptions = providers.some((value) => value.type !== ProviderType.SAML)

  // If we have only one SSO IdP, the button name will  be 'Continue with SSO'
  if (ssoProviders.length == 1) {
    ssoProviders[0].name = 'SSO'
  }

  const { pathname } = useRouter()
  const queryParams = useSearchParams()
  const otherLoginOptionsURL = () => {
    const searchParams = new URLSearchParams()
    queryParams.forEach((value, key) => searchParams.set(key, value))
    searchParams.set('authOption', 'other')

    return `${pathname}?${searchParams.toString()}`
  }

  return (
    ssoProviders.length > 0 && (
      <Stack direction="column" spacing={0}>
        <Stack direction="column" spacing={6}>
          {isVisible && (
            <Typography color="default" variant="label" component="p" align="left">
              Your organization has enabled Single Sign-on (SSO). Use your organization login credentials to continue.
            </Typography>
          )}
          <FirebaseUiAuth
            containerId="firebaseui-auth-container-sso"
            tenantId={tenantId!}
            providers={ssoProviders}
            onAuthSuccess={onAuthSuccess}
            onAuthFailure={onAuthFailure}
            onRedirectStatusChange={(redirecting) => {
              setIsVisible(!redirecting)
              onVisibilityChange(!redirecting)
            }}
            setAuthError={setAuthError}
          />
        </Stack>
        {isVisible && hasOtherLoginOptions && (
          <Typography variant="label" align="left">
            <Link href={otherLoginOptionsURL()} target="_self">
              Other login options
            </Link>
          </Typography>
        )}
      </Stack>
    )
  )
}

export default SSOLogin
